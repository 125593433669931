<template>
<v-container fluid>
    <v-row>
        <v-col cols="12">
            <div class="d-flex">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                            >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
                <v-spacer></v-spacer>
            </div>
        </v-col>
        <v-col cols="12">
            <v-card outlined class="p-0">
                <v-container fluid class="p-3">
                    <v-row>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                prepend-inner-icon="mdi-watermark"
                                clearable
                                solo
                                dense
                                v-model="mill"
                                :items="mills"
                                item-value="mill_id"
                                item-text="mill_name"
                                label="Mill ID"
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                                @change="(event) => getVendor(event)"
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete
                                prepend-inner-icon="mdi-account-supervisor"
                                clearable
                                solo
                                dense
                                v-model="vendor"
                                :items="vendors"
                                item-value="vendor_id"
                                item-text="vendor_name"
                                label="Vendor"
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                                :loading="isLoadingVendor"
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-menu
                                ref="modal"
                                v-model="modal"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    solo
                                    dense
                                    v-model="date_from"
                                    label="Start Date"
                                    persistent-hint
                                    prepend-inner-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date_from"
                                    no-title
                                    @input="modal = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2" >
                            <v-menu
                                ref="modal_to"
                                v-model="modal_to"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    solo
                                    dense
                                    v-model="date_to"
                                    label="End Date"
                                    persistent-hint
                                    prepend-inner-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date_to"
                                    no-title
                                    @input="modal_to = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete  
                                prepend-inner-icon="mdi-tag-check"
                                clearable
                                solo
                                dense
                                v-model="approve"
                                :items="approves"
                                item-value="value"
                                item-text="descr"
                                label="APROVED"
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-autocomplete  
                                prepend-inner-icon="mdi-list-status"
                                clearable
                                solo
                                dense
                                v-model="status"
                                :items="statuses"
                                item-value="value"
                                item-text="status"
                                label="Status"
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                            >
                            </v-autocomplete>
                        </v-col>
                        
                    </v-row>
                    <v-row align="center" justify="center" class="mt-5">
                        <v-col class="col-6" sm="6" md="2">
                            <v-btn class="border-12" block  style="padding: 21px;"
                            elevation="2" small @click="clear()">Clear</v-btn>
                            
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-btn class="border-12" block style="padding: 21px;"
                            color="info" elevation="2" small @click="fetchData()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card outlined class="p-0" >
                <v-card-text class="mt-0">
                    <v-data-table
                        dense
                        :headers="headers"
                        :items="po_headers"
                        class="elevation-1 mt-3"
                        :items-per-page="30"
                        :footer-props="{
                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                        }"
                        :loading="$store.state.overlay"
                        height="400"
                        fixed-header
                        :divider="true"
                        :light="true"
                        :search="search" 
                        :item-class="tr_datatable"
                    >    
                        <template v-slot:top>
                            <v-toolbar flat color="white" class="mt-2 mb-2">
                                <div class="d-flex w-100">
                                    <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="search" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                    small
                                    color="#fff"
                                    class="py-5 mr-3"
                                    @click="fetchData()"
                                    >
                                        <v-icon>mdi-cached</v-icon>
                                    </v-btn>
                                    <v-btn
                                    small
                                    color="#005c37"
                                    class="py-5 mr-3 text-white"
                                    >
                                        <v-icon>mdi-file-excel</v-icon>
                                        <download-excel
                                            class="text"
                                            :fetch           = "exportExcel"
                                            :fields="headersColumn"
                                            :before-generate = "startDownload"
                                            :before-finish   = "finishDownload">
                                            Export Excel
                                        </download-excel>
                                    </v-btn>
                                </div>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.dt_po`]="{ item }">
                            {{ (new Date(new Date(item.dt_po) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                        </template> 
                        <template v-slot:[`item.dt_shipped`]="{ item }">
                            {{ (new Date(new Date(item.dt_shipped) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                        </template> 
                        <template v-slot:[`item.dt_approve1`]="{ item }">
                            {{ (new Date(new Date(item.dt_approve1) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                        </template> 
                        <template v-slot:[`item.dt_approve2`]="{ item }">
                            {{ (new Date(new Date(item.dt_approve2) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                        </template> 
                        <template v-slot:[`item.qty`]="{ item }">
                            {{ $store.getters.convertToCurrencyNoFrac(item.qty) }}
                        </template> 
                        <template v-slot:[`item.wgt`]="{ item }">
                            {{ $store.getters.convertToCurrencyUs(item.wgt) }}
                        </template> 
                        <template v-slot:[`item.amt_subtotal`]="{ item }">
                            {{ $store.getters.convertToCurrencyNoFrac(item.amt_subtotal) }}
                        </template> 
                        <template v-slot:[`item.amt_ppn`]="{ item }">
                            {{ $store.getters.convertToCurrencyNoFrac(item.amt_ppn) }}
                        </template> 
                        <template v-slot:[`item.amt_net`]="{ item }">
                            {{ $store.getters.convertToCurrencyNoFrac(item.amt_net) }}
                        </template> 
                        <!-- <template v-slot:[`item.status_aprv`]="{ item }">
                            <v-btn text color="red" v-if="item.dt_aprv === '1900-01-01 00:00:00.000'">Not Approved</v-btn>
                            <v-btn text color="success" v-else>{{ (new Date(new Date(item.dt_aprv) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}</v-btn>
                        </template>  -->
                        <template v-slot:[`item.action`]="{ item }">
                            <v-menu
                                bottom
                                left
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        color="grey"
                                        class="text-center"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-list dense>
                                    <v-list-item @click="showDetailItem(item)">
                                        <v-list-item-icon>
                                            <v-icon>mdi-eye</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Detail</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="item.dt_aprv === '1900-01-01 00:00:00.000' && aprvPO" @click="showApproval(item, 'aprv')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-check</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Approve</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="item.dt_aprv != '1900-01-01 00:00:00.000' && aprvPO" @click="showApproval(item, 'unaprv')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-close</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>UnApprove</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template> 
                    </v-data-table>
                </v-card-text>
            </v-card>
            <v-dialog
                v-model="dialog"
                persistent
                max-width="1200px"
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <v-card class="rounded-l">
                    <v-card-title class="text-center mb-0 pb-0">
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex">
                                    <div class="ml-5">
                                        {{ detail ? detail.mill_name : '' }}  # {{ detail ? detail.po_id : '' }} 
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="mx-2"
                                        fab
                                        x-small
                                        color="#e83e8c;"
                                        @click="[dialog = false]"
                                    >
                                        <v-icon>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-divider></v-divider>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="ma-0">
                        <v-data-table
                            dense
                            :headers="item_header"
                            :items="po_items"
                            class="elevation-1 mt-3"
                            :items-per-page="30"
                            :footer-props="{
                                'items-per-page-options': [10, 20, 30, 40, 50, -1]
                            }"
                            :loading="$store.state.overlay"
                            height="400"
                            fixed-header
                            :divider="true"
                            :light="true"
                            :search="searchItem" 
                            :item-class="tr_datatable"
                        >    
                            <template v-slot:top>
                                <v-toolbar flat color="white" class="mt-2 mb-2">
                                    <div class="d-flex w-100">
                                        <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                        small
                                        color="#005c37"
                                        class="py-5 mr-3 text-white"
                                        >
                                            <v-icon>mdi-file-excel</v-icon>
                                            <download-excel
                                                class="text"
                                                :fetch           = "exportExcelItem"
                                                :fields="headersColumnItem"
                                                :before-generate = "startDownload"
                                                :before-finish   = "finishDownload">
                                                Export Excel
                                            </download-excel>
                                        </v-btn>
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.qty`]="{ item }">
                                {{ $store.getters.convertToCurrencyNoFrac(item.qty) }}
                            </template> 
                            <template v-slot:[`item.wgt`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.wgt) }}
                            </template> 
                            <template v-slot:[`item.unit_price`]="{ item }">
                                {{ $store.getters.convertToCurrencyNoFrac(item.unit_price) }}
                            </template> 
                            <template v-slot:[`item.amt_net`]="{ item }">
                                {{ $store.getters.convertToCurrencyNoFrac(item.amt_net) }}
                            </template> 
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="dialogAprv"
                persistent
                max-width="600px"
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <v-card class="rounded-l">
                    <v-card-title class="text-center mb-0 pb-0">
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex">
                                    <div class="ml-5">
                                        {{ detail ? detail.mill_name : '' }}  # {{ detail ? detail.po_id : '' }} 
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="mx-2"
                                        fab
                                        x-small
                                        color="#e83e8c;"
                                        @click="[dialogAprv = false]"
                                    >
                                        <v-icon>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-divider></v-divider>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="ma-0">
                        <v-container class="p-4">
                            <v-row align="center" justify="center">
                                <v-col cols="8" class="text-center">
                                    <v-icon color="primary">mdi-check</v-icon>
                                    <h6 v-if="typeAprv == 'aprv'">
                                        Are you sure Approve this PO {{ detail ? detail.po_id : '' }} ?
                                    </h6>
                                    <h6 v-else-if="typeAprv == 'unaprv'">
                                        Are you sure UnApprove this PO {{ detail ? detail.po_id : '' }} ?
                                    </h6>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center" class="mt-12">
                                <v-col class="col-6" sm="6" md="4">
                                    <v-btn class="border-12" block  style="padding: 21px;"
                                    elevation="2" small @click="dialogAprv = false">Cancel</v-btn>
                                </v-col>
                                <v-col class="col-6" sm="6" md="4" v-if="typeAprv == 'aprv'">
                                    <v-btn class="border-12" block style="padding: 21px;"
                                    color="info" elevation="2" small @click="submitApprove()"><v-icon>mdi-check</v-icon> Approve</v-btn>
                                </v-col>
                                <v-col class="col-6" sm="6" md="4" v-else-if="typeAprv == 'unaprv'">
                                    <v-btn class="border-12" block style="padding: 21px;"
                                    color="info" elevation="2" small @click="submitApprove()"><v-icon>mdi-close</v-icon> UnApprove</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data(){
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/kka',
                },
                {
                text: 'Data Approval',
                disabled: false,
                href: '#',
                },
                {
                text: 'PO Approval',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            headers:[
                { text: '', value: 'action' , align:'left', groupable: false},
                // { text: 'STAT APRV', value: 'status_aprv' , align:'left', groupable: false, width:'150'},
                { text: 'MILL', value: 'mill_name' , align:'left', groupable: false, width:'200'},
                { text: 'PO ID', value: 'po_id' , align:'left',width:'150', groupable: false, width:'200'},
                { text: 'Vendor', value: 'vendor_name' , align:'left',width:'200', groupable: false},
                { text: 'PO Date', value: 'dt_po', align:'left', groupable: false, width:'150'},
                { text: 'Ship Date', value: 'dt_shipped' , align:'left', groupable: false, width:'150'},
                { text: 'Pay Term', value: 'pay_term' , align:'left', groupable: false, width:'150'},
                { text: 'Qty', value: 'qty' , align:'left', groupable: false, width:'100'},
                { text: 'Weight', value: 'wgt' , align:'left', groupable: false, width:'100'},
                { text: 'Currency', value: 'curr_id' ,width:100, align:'left', groupable: false},
                { text: 'Amt Subtotal', value: 'amt_subtotal', align:'left', groupable: false, width:'150'},
                { text: 'Amt Discount', value: 'amt_disc', align:'left', groupable: false, width:'150'},
                { text: 'Amt PPN', value: 'amt_ppn' , align:'left', groupable: false, width:'150'},
                { text: 'Amt Net', value: 'amt_net' , align:'left', groupable: false, width:'150'},
                { text: 'Status', value: 'stat' , align:'left', groupable: false, width:'150'},
                { text: 'Memo', value: 'memo_txt' , align:'left', groupable: false, width:'200'},
                { text: 'Aprrove Status', value: 'aprv_flag' , align:'left', groupable: false, width:'200'},
                { text: 'Date Aprv 1', value: 'dt_approve1' , align:'left', groupable: false, width:'200'},
                { text: 'Date Aprv 2', value: 'dt_approve2' , align:'left', groupable: false, width:'200'},
            ],
            headersColumn: {
                'MILL': 'mill_name',
                'PO ID': 'po_id',
                'Vendor': 'vendor_name',
                'Date PO': 'dt_po',
                'Date Ship': 'dt_shipped',
                'Pay Term': 'pay_term',
                'Qty': 'qty',
                'Wgt': 'wgt',
                'Currency': 'curr_id',
                'Amt Subtotal': 'amt_subtotal',
                'Amt Discount': 'amt_disc',
                'Amt PPN': 'amt_ppn',
                'Amt Net': 'amt_net',
                'Status': 'stat',
                'Memo': 'memo_txt',
                'Aprrove Status':'aprv_flag',
                'Date Aprv 1':'dt_approve1',
                'Date Aprv 2':'dt_approve2',
            },
            po_headers: [],
            po_items: [],
            item_header:[
                { text: 'ProdCode', value: 'prod_code' , align:'left',width:'150', groupable: false},
                { text: 'Item Desc', value: 'item_desc', align:'left', groupable: false, width:'200'},
                { text: 'Qty', value: 'qty' , align:'left', groupable: false, width:'100'},
                { text: 'Weight', value: 'wgt' , align:'left', groupable: false, width:'100'},
                { text: 'Unit Meas', value: 'unit_meas' ,width:150, align:'left', groupable: false},
                { text: 'Unit Price', value: 'unit_price', align:'left', groupable: false, width:'150'},
                { text: 'Amt Net', value: 'amt_net' , align:'left', groupable: false, width:'150'},
                { text: 'Remark', value: 'remark' , align:'left', groupable: false, width:'250'}
            ],
            headersColumnItem: {
                'MILL ID': 'mill_id',
                'PO ID': 'po_id',
                'Item': 'po_item',
                'Prodcode': 'prod_code',
                'Item Desc': 'item_desc',
                'Qty': 'qty',
                'Weight': 'wgt',
                'Unit Meas': 'unit_meas',
                'Unit Price': 'unit_price',
                'Amt Net': 'amt_net',
                'Remark': 'remark'
            },
            searchItem: '',
            mills: [],
            mill: '',
            vendors: [],
            vendor: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: '',
            approves: [
                {
                    descr: 'Approved',
                    value: 'Y'
                },
                {
                    descr: 'Not Approved',
                    value: 'N'
                }
            ],
            approve: '',
            status: '',
            statuses: [
                {
                    status: 'OPEN',
                    value: 'O'
                },
                {
                    status: 'CLOSE',
                    value: 'C'
                }
            ],
            search: '',
            isLoadingVendor: false,
            dialog: false,
            dialogAprv: false,
            detail: null,
            aprvPO: false,
            typeAprv: ''
        }  
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getEnvConf()
        await this.getMills()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        tr_datatable(item) {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        async getEnvConf(){
            const respData = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KKA&appl_id=WEBKKA&var_id=APRVPO2&groupid=${this.$store.state.kka.group_id}`, null, false, false, false)            

            if (respData.data.data != null) {  
                this.aprvPO = true         
            }
        },
        async getMills(event){
            if (event === '' || event === null) {
                this.mill = ''
            } else {
                const respData = await backendApi.fetchCore(`/api/master/kka/mill?mill_id=${this.mill ? this.mill : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.mills = respData.data.data
                }
            }
        },
        async getVendor(event){
            if (event === '' || event === null) {
                this.vendor = ''
            } else {
                this.isLoadingVendor = true
                const respData = await backendApi.fetchCore(`/api/master/kka/vendor?mill_id=${this.mill ? this.mill : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.vendors = respData.data
                    this.isLoadingVendor = false
                }
            }
        },
        async submitApprove(){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'mill_id': this.detail ? this.detail.mill_id : '',
                'po_id': this.detail ? this.detail.po_id : '',
                'type' : this.typeAprv
            }

            const respData = await backendApi.fetchCore('/api/v3/kka/po/approve', reqBody, false, false, false)
            if (respData.status === 200) {
                this.dialogAprv = false
                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Approve",
                    visible: true
                };
                await this.fetchData()
            }  else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data,
                    visible: true
                };
            }
            this.$store.dispatch('setOverlay', false)

        },
        async showApproval(item, type){
            this.typeAprv = type
            this.detail = item
            this.dialogAprv = true
        },
        async showDetailItem(item){
            console.log(item);
            this.$store.dispatch('setOverlay', true)

            this.detail = item

            var reqBody = {
                'mill_id': this.detail ? this.detail.mill_id : '',
                'po_id': this.detail ? this.detail.po_id : '',
            }

            const respData = await backendApi.fetchCore('/api/v3/kka/po/show', reqBody, false, false, false)
            if (respData.status === 200) {
                this.po_items = respData.data.data
                this.dialog = true
            } 

            this.$store.dispatch('setOverlay', false)

        },
        clear(){
            this.mill = ''
            this.vendor = ''
            this.date_from = ''
            this.date_to = ''
            this.status = ''
            this.approve = ''
            this.po_headers = []
        },
        async fetchData(){
            this.$store.dispatch('setOverlay', true)

            if (this.date_from == '') {
                this.$store.dispatch('setOverlay', false)

                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please fill start Date",
                    visible: true
                };
                return false
            }

            var start = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10) : ''
            var end = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10) : ''

            var reqBody = {
                'dateFrom': start,
                'dateTo': end,
                'mill_id': this.mill,
                'vendor_id': this.vendor ? this.vendor : '',
                'stat': this.status ? this.status : '',
                'txtApproved': this.approve ? this.approve : ''
            }

            const respData = await backendApi.fetchCore('/api/v3/kka/po', reqBody, false, false, false)
            if (respData.status === 200) {
                this.po_headers = respData.data.data
            } 

            this.$store.dispatch('setOverlay', false)

        },
        async exportExcel(){
            if (this.date_from == '') {
                this.$store.dispatch('setOverlay', false)

                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please fill start Date",
                    visible: true
                };
                return false
            }

            var start = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10) : ''
            var end = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10) : ''

            var reqBody = {
                'dateFrom': start,
                'dateTo': end,
                'mill_id': this.mill,
                'vendor_id': this.vendor ? this.vendor : '',
                'stat': this.status ? this.status : '',
                'txtApproved': this.approve ? this.approve : ''
            }

            const respData = await backendApi.fetchCore('/api/v3/kka/po', reqBody, false, false, false)
            if (respData.status === 200) {
                return respData.data.data
            } 
        },
        async exportExcelItem(){
            var reqBody = {
                'mill_id': this.detail ? this.detail.mill_id : '',
                'po_id': this.detail ? this.detail.po_id : '',
            }

            const respData = await backendApi.fetchCore('/api/v3/kka/po/show', reqBody, false, false, false)
            if (respData.status === 200) {
                return respData.data.data
            } 
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    },    
}
</script>

<style>

</style>